import React from "react";
import { connect } from "react-redux";
import { CardText, CardTitle, Card } from "material-ui/Card";
import Paper from "material-ui/Paper";
import ContentClear from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Checkbox from "material-ui/Checkbox";

import Treecamp_img7 from "../../../assets/images/londo_treecamp9.jpeg";
import VartyChaletcamp_img14 from "../../../assets/images/may16_varty4.jpeg";
import PrivateGraniteSuites_img17 from "../../../assets/images/2018.granite9-min.jpg";
import Founderscamp_img2 from "../../../assets/images/founders-header-original.jpg";
import Pioneercamp_img4 from "../../../assets/images/living7-min.jpg";
import General1 from "../../../assets/images/General Daytrippers-min.jpg";
import General2 from "../../../assets/images/General Pilot Rooms-min.jpg";
import General3 from "../../../assets/images/Landrovers Honeymoon-min.jpg";
import General4 from "../../../assets/images/Landrovers Private-min.jpg";
import General5 from "../../../assets/images/Lodge Internal Accounts-min.jpg";
import General6 from "../../../assets/images/Lodge Staff Accounts.jpg";

import {
  startSetConfig,
  startRemoveLineItem,
  setRate,
  removeRate,
  setOverride,
  setLevi,
} from "./../../../actions/booking";
import formatRate from "./../../../functions/formatRate";
import WithLoading from "./../../../hoc/WithLoading";
import { getRole } from "../../../functions/getRole";
const moment = require("moment");

const Span = ({ children }) => <span>{children}</span>;
const SpanWithLoading = WithLoading(Span);
var priceObj;

let checkchange = false;
let firsttime = false;
let originalvalue;

class RoomConfig extends React.Component {
  state = {
    role: getRole(),
    rates: priceObj,
    checkedA: false,
  };

  onChangeInput = (newValue) => {
    this.setState({
      rates: newValue.target.value,
    });
    var nPrice = newValue.target.value.replace(/,/g, "").replace(/ZAR/g, " ");
    var newPrice = nPrice.split(".")[0];
    this.props.setRate({
      lineId: this.props.lineId,
      rate: {
        gross: Number(newPrice),
        nett: Number(newPrice),
        comm: Number(0),
        tax: Number(newPrice * 0.122807),
      },
    });
    this.props.setOverride({
      lineId: this.props.lineId,
      override: newPrice,
    });
  };

  handleChecked = (event) => {
    this.setState({ checkedA: event.target.checked });
    // removeRate({
    //     lineId: this.props.lineId
    // })
  };

  rateChange = ({ lineId, rateGroupId, number }) => {
    this.props.setConfig({
      lineId,
      rateGroupId,
      number,
    });
  };

  getGuestNumberArray = (maxNumber) => {
    let guestNumberArray = [];
    for (let i = maxNumber; i >= 0; i--) {
      guestNumberArray.push(i.toString());
    }
    return guestNumberArray;
  };
  getNumberOfGuest = () => {
    let numberguests = 0;

    for (let i = 0; i < this.props.rateGroups.length; i++) {
      numberguests += Number(this.props.rateGroups[i].number);
    }

    return numberguests;
  };
  removeLineItem = (lineId) => {
    this.props.removeLineItem(lineId);
  };

  isWarning = (isOverCapacity, noSelection) => {
    return (
      (isOverCapacity || noSelection) && this.state.role === "externalAgent"
    );
  };

  imageMap(propertyNameClicked, campNameClicked) {
    let imageCamp;

    if (propertyNameClicked === "Tree Camp") {
      imageCamp = Treecamp_img7;
    } else if (propertyNameClicked === "Varty Camp") {
      imageCamp = VartyChaletcamp_img14;
    } else if (propertyNameClicked === "Private Granite Suites") {
      imageCamp = PrivateGraniteSuites_img17;
    } else if (propertyNameClicked === "Founders Camp") {
      imageCamp = Founderscamp_img2;
    } else if (propertyNameClicked === "Pioneer Camp") {
      imageCamp = Pioneercamp_img4;
    } else if (propertyNameClicked === "General") {
      if (campNameClicked.includes("Day Trippers")) {
        imageCamp = General1;
      } else if (campNameClicked.includes("Pilot")) {
        imageCamp = General2;
      } else {
        imageCamp = General1;
      }
    } else if (propertyNameClicked === "Landrovers") {
      if (campNameClicked.includes("Honeymoon")) {
        imageCamp = General3;
      } else if (campNameClicked.includes("Private")) {
        imageCamp = General4;
      }
    } else if (propertyNameClicked === "Lodge: Miscellaneous") {
      if (campNameClicked.includes("Internal")) {
        imageCamp = General5;
      } else if (campNameClicked.includes("Staff")) {
        imageCamp = General6;
      }
    }

    return imageCamp;
  }

  render() {
    const {
      rateGroups,
      lineId,
      accomName,
      accomId,
      max_capacity,
      isOverCapacity,
      noSelection,
    } = this.props;

    const SPECIAL_RATE_ACCOMMODATION_IDS = [
      "RS4", "RS5", "WB26", "WB27", "WB28", "WB29", "WB30",
      "WB31", "WB32", "WB18", "11ede040a643d4c58fa8ac1f6b1b6a6e",
      "RS6", "WB25"
    ];

    let price;
    let checkAgent;
    let sabilevi = 0;
    let arrival = moment(this.props.arrival, "YYYY-MM-DD");
    let departure = moment(this.props.departure, "YYYY-MM-DD");
    let numberguests = this.getNumberOfGuest();

    let sabilevy;
    const currentDate = moment();
    const december152025 = moment('2025-12-15');
    const january12025 = moment('2025-01-01');

    // Check if we're past 2025-01-01 or if arrival is after Dec 15, 2025
    if (currentDate.isAfter(january12025) || arrival.isAfter(december152025)) {
      // Determine which rate to use based on accommodation ID
      if (SPECIAL_RATE_ACCOMMODATION_IDS.includes(accomId)) {
        sabilevy = 573.99;
      } else {
        sabilevy = 957.50;
      }
    } else {
      // Original rate logic for pre-2025 dates
      switch (arrival.year()) {
        case 2020:
          sabilevy = 138;
          break;
        case 2021:
          sabilevy = 151.8;
          break;
        case 2022:
          sabilevy = 151.8;
          break;
        case 2023:
          sabilevy = 345;
          break;
        case 2024:
          sabilevy = 373.75;
          break;
        case 2025:
          sabilevy = 410;
          break;
        case 2026:
          sabilevy = 410;
          break;
        default:
          sabilevy = 373.75;
          break;
      }
    }

    let sabinights = departure.diff(arrival, "days");
    // Cap the nights charged for the levy at 3 max.
    if (sabinights > 3) {
      sabinights = 3;
    }

    sabilevi = sabinights * sabilevy * numberguests;
    this.props.setLevi({ lineId: this.props.lineId, levy: sabilevi });

    if (this.state.role != "externalAgent") {
      checkAgent = (
        <Checkbox
          label="Override rate option"
          checked={this.state.checkedA}
          onCheck={this.handleChecked.bind(this)}
          value="checkedA"
        />
      );
    }

    if (this.props.rate != undefined) {
      firsttime = false;
      priceObj = formatRate(this.props.rate.gross);
      if (firsttime == false) {
        firsttime = true;
        originalvalue = priceObj;
      }
    }

    if (this.state.checkedA) {
      this.state.rates = priceObj;
      price = (
        <input
          className="rate-input-override"
          type="text"
          value={this.state.rates}
          onChange={this.onChangeInput.bind(this)}
        />
      );
      checkchange = true;
    } else {
      this.state.rates = originalvalue;
      price = originalvalue;
      if (firsttime == true && checkchange == true) {
        checkchange = false;
        this.setState({
          rates: originalvalue,
        });
        var nPrice = originalvalue.replace(/,/g, "").replace(/ZAR/g, " ");
        var newPrice = nPrice.split(".")[0];
        this.props.setRate({
          lineId: this.props.lineId,
          rate: {
            gross: Number(newPrice),
            nett: Number(newPrice),
            comm: Number(0),
            tax: Number(newPrice * 0.122807),
          },
        });
        this.props.setOverride({
          lineId: this.props.lineId,
          override: newPrice,
        });
      }
    }

    let propertyNameClicked;
    let campNameClicked;

    if (this.props.propertyName.length > 0) {
      propertyNameClicked = this.props.propertyName;
      campNameClicked = this.props.accomName;
    }

    const imageCamp = this.imageMap(propertyNameClicked, campNameClicked);

    return (
      <Paper
        className={`room-config ${this.isWarning(isOverCapacity, noSelection) && "room-config__warning"
          }`}
      >
        <div className="room-config-header">
          <CardTitle
            subtitle={
              accomName.includes("Camp Chalet") ? "Camp Chalet" : accomName
            }
          />
          <div className="room-config-remove">
            <IconButton
              styles={{ width: 2 }}
              onClick={() => this.removeLineItem(lineId)}
            >
              <ContentClear />
            </IconButton>
          </div>
        </div>

        <div className="room-config-left">
          <img className="roomconfig-image" src={imageCamp} />
        </div>

        <div className="room-config-right">
          <div className="room-guests">
            {rateGroups.map((rateGroup, i) => (
              <CardText className="room-config-select" key={i}>
                <div className="bookingmodalheaders">{rateGroup.desc}</div>
                <span>
                  <button
                    className="incr-dec-button"
                    onClick={(v) => {
                      firsttime = false;
                      checkchange = false;

                      this.rateChange({
                        lineId,
                        rateGroupId: rateGroup.id,
                        number: String(
                          Number(rateGroup.number) - 1 < 0
                            ? 0
                            : Number(rateGroup.number) - 1
                        ),
                      });
                    }}
                  >
                    -
                  </button>
                </span>
                <button className="incrbuttonmiddle">{rateGroup.number}</button>

                <span>
                  <button
                    className="incr-dec-button"
                    onClick={(v) => {
                      firsttime = false;
                      checkchange = false;

                      this.rateChange({
                        lineId,
                        rateGroupId: rateGroup.id,
                        number: String(Number(rateGroup.number) + 1),
                      });
                    }}
                  >
                    +
                  </button>
                </span>
              </CardText>
            ))}
          </div>

          <div className="warning-booking-modal">
            <BookingWarning
              isOverCapacity={isOverCapacity}
              noSelection={noSelection}
            />
          </div>

          <div className="room-prices">
            <CardText className="max-capacity">
              <span className="italic-lower">Max Capacity: </span>
              <span className="italic-lower-number"> {max_capacity}</span>
            </CardText>

            <CardText className="roon-config-price-actual">
              <span className="roon-config-price-specific">Price: </span>
              <span className="room-config-price-specific-value-price">
                <SpanWithLoading isLoading={!this.props.rate}>
                  {price}
                </SpanWithLoading>
              </span>
            </CardText>

            <CardText className="roon-config-price-commission">
              <span className="roon-config-price-specific"> Commission: </span>
              <span className="room-config-price-specific-value-commission">
                <SpanWithLoading isLoading={!this.props.rate}>
                  {this.props.rate && formatRate(this.props.rate.comm)}
                </SpanWithLoading>
              </span>
            </CardText>
            <CardText className="roon-config-price-levy">
              <span className="roon-config-price-specific">
                {" "}
                Sabi Sand levy:{" "}
              </span>
              <span className="room-config-price-specific-value-levy">
                <SpanWithLoading isLoading={!this.props.rate}>
                  {formatRate(sabilevi)}
                </SpanWithLoading>
              </span>
            </CardText>

            <div className="rate-override">{checkAgent}</div>
          </div>
        </div>
      </Paper>
    );
  }
}

const BookingWarning = ({ isOverCapacity, noSelection }) => {
  if (isOverCapacity) {
    return (
      <CardText className="warning-booking-modal-inner">
        Warning: Max capacity exceeded.
      </CardText>
    );
  } else if (noSelection) {
    return (
      <CardText className="warning-booking-modal-inner">
        Warning: Empty room.
      </CardText>
    );
  }
  return null;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConfig: ({ lineId, rateGroupId, number }) => {
      dispatch(startSetConfig(lineId, rateGroupId, number));
    },
    removeLineItem: (lineId) => dispatch(startRemoveLineItem(lineId)),
    setRate: ({ lineId, rate }) => {
      dispatch(setRate(lineId, rate));
    },
    setLevi: ({ lineId, levy }) => {
      dispatch(setLevi(lineId, levy));
    },
    setOverride: ({ lineId, override }) => {
      dispatch(setOverride(lineId, override));
    },
  };
};

export default connect(undefined, mapDispatchToProps)(RoomConfig);
